<!-- @format -->

<template>
  <div
    class="scroll-container"
    :class="{ 'overflow-hidden': !animationComplete }"
  >
    <!--  <DesktopHeader />  -->
    <transition name="fade">
      <div
        v-if="showLoadingSection"
        class="loading-section"
        :class="$mq"
        ref="loadingSection"
      >
        <div class="loading-title">
          <img src="@/assets/images/top/new/loading_title.png" alt="" />
        </div>
      </div>
    </transition>

    <div class="content-container">
      <DesktopTop :ratio="ratio" :trailer="trailer" :kinostart="kinostart" />

      <!--
    <Crew />
    <Musik />-->
      <Inhalt />

      <!-- 
    <Fotos />-->
      <BaseFooter :billing="true" :kinostart="kinostart" />

      <BaseYoutube :youtube="$config.youtube" :margin="100" />
    </div>
  </div>
</template>

<script>
  import Inhalt from '@/views/Inhalt.vue'

  /* 
  import Fotos from '@/views/Fotos.vue'
import Hintergrundinfos from '@/views/Hintergrund.vue'
import Partner from '@/views/Partner.vue'
  import Regie from '@/views/Regie.vue'
  import Timeline from '@/views/Timeline.vue'
  import Statement from '@/views/Statement.vue'
  import Clip from '@/views/Clip.vue'
   */
  import moment from 'moment'

  export default {
    data() {
      return {
        showLoadingSection: true,
        fadeIn: false,
        animationComplete: false,
      }
    },
    components: {
      Inhalt,
      /*  
        Fotos,
    Hintergrundinfos,
    Partner,
      Regie, 
      Fotos,
      Timeline,
      Statement,
      Clip,
  */
    },
    created() {
      window.addEventListener('scroll', this.handleScroll)
    },
    computed: {
      kinostart() {
        return moment() < moment(this.$config.release_line_1.date)
          ? this.$config.release_line_1.line_before
          : this.$config.release_line_1.line_after
      },
      ratio() {
        return (
          this.$store.getters['Window/isSize'].width /
          this.$store.getters['Window/isSize'].height
        )
      },
      trailer() {
        return this.$config.youtube.id !== ''
      },
    },
    mounted() {
      this.$nextTick(() => {
        const loadingSection = this.$refs.loadingSection

        loadingSection.addEventListener('animationend', () => {
          loadingSection.classList.add('animate-complete')
          setTimeout(() => {
            this.showLoadingSection = false
            setTimeout(() => {
              this.animationComplete = true
            }, 2500)
          }, 1500) // 6000 milliseconds = 6 seconds
        })
      })
    },
    methods: {
      handleScroll() {
        const element = document.querySelector('.maedchen_fadein')
        if (element) {
          const rect = element.getBoundingClientRect()
          const windowHeight =
            window.innerHeight || document.documentElement.clientHeight
          this.fadeIn = rect.top <= windowHeight
        }
      },
      logout() {
        this.$store.dispatch('Auth/userSignOut')
      },
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .scroll-container {
    z-index: 0;
    position: relative;

    width: 100%;
    &.overflow-hidden {
      overflow-y: hidden;
      overflow-x: hidden;
    }
    .loading-section {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 99999;
      background-image: url('~@/assets/images/top/new/loading_image.jpg');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
      height: 100vh; /* Vollständige Bildschirmhöhe verwenden */
      display: flex;
      justify-content: center;
      align-items: center;
      animation: scaleAnimation 2.5s ease-in-out;
      &.xxxl,
      &.xxxxl {
        height: 1360px;
      }
      .loading-title {
        opacity: 0;
        transition: opacity 1s;
        margin-top: -100px;
      }
    }
    @keyframes scaleAnimation {
      0% {
        transform: scale(1.1);
      }
      100% {
        transform: scale(1);
      }
    }
    .loading-section.animate-complete .loading-title {
      opacity: 1;
    }
    .content-container {
      height: 100vh;
    }
  }
</style>
