<!-- @format -->

<template>
  <footer class="footer" :class="$mq">
    <div class="footer__content" :class="$mq">
      <!-- SECTION Footer Company -> Chooses Footer by $config.verantwortlich -> look @ computed 
            <div class="footer__tt" :class="$mq">
        <img
          :src="require(`@/assets/images/` + $config.tt_small)"
          alt=""
          :class="$mq"
        />
      </div>-->
      <component
        class="footer__company"
        :is="companyFooter"
        :teilnahmebedingung="teilnahmebedingung"
      ></component>
      <component class="footer__social" :is="companySocial"></component>
    </div>
    <div class="footer__media" :class="$mq">
      <img src="@/assets/images/footer/media.png" alt="" />
    </div>
    <div class="cb-restore"></div>
  </footer>
</template>

<script>
  export default {
    props: {
      billing: {
        type: Boolean,
        default: true,
      },
      youtubenote: {
        type: Boolean,
        default: true,
      },
      kinostart: {
        type: String,
        default: 'Releaseline',
      },
      teilnahmebedingung: {
        type: String,
        default: 'leer',
      },
    },
    computed: {
      companyFooter() {
        return () =>
          import(
            `@/components/Base/Elements/Companies/${this.$config.verantwortlich.toLowerCase()}/Footer.vue`
          )
      },
      companySocial() {
        return () =>
          import(
            `@/components/Base/Elements/Companies/${this.$config.verantwortlich.toLowerCase()}/Social.vue`
          )
      },
    },
  }
</script>

<style lang="scss">
  .footer {
    $self: &;
    z-index: 5;
    position: relative;
    text-align: center;
    height: 326px;
    font-size: 0.875rem;
    width: 100%;
    padding-bottom: 5rem;
    background-color: white;
    margin-top: 22px;
    &.lg {
      height: 376px;
    }
    &.sm,
    &.md {
      height: 420px;
      padding-bottom: 2rem;
    }
    &__content {
      position: relative;
      margin: 0 auto;
      font-family: 'Jost';
      &.sm,
      &.md {
        padding: 0;
        margin-top: -5px;
      }
    }
    .footer__media {
      margin-bottom: 25px;
      &.sm {
        img {
          width: 60%;
        }
      }
      &.md {
        img {
          width: 50%;
        }
      }
    }
    #{$self}__billing {
      margin-top: 15px;
      &.sm,
      &.md {
        margin-top: 10px;
      }
      &.sm,
      &.md,
      &.lg,
      &.xl {
        padding: 0 5%;
      }
      &.xxl,
      &.xxxl,
      &.xxxxl {
        img {
          max-width: 1000px;
        }
      }
    }
  }
</style>
