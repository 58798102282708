<template>
  <div class="inhalt-overview" :class="$mq" id="inhalt">
    <div class="inhalt desktop-inhalt" :class="$mq" id="desktop-inhalt">
      <div class="desktop-inhalt-content">
        <div class="teilen-desktop" :class="$mq">
          <div class="teilen-desktop-content" :class="$mq">
            <div class="sharing" :class="$mq">
              <div class="bg-teilen">
                <span>Teilen auf</span>
              </div>
              <div class="icons">
                <a
                  target="_blank"
                  :href="`https://www.facebook.com/sharer/sharer.php?u=${$config.url}`"
                  class="share__item share__fb"
                  ><img src="@/assets/images/top/fb.png" alt="" />
                </a>
                <a
                  :href="`https://twitter.com/intent/tweet?text=${$config.twitter.text}`"
                  target="_blank"
                  class="share__item share__twitter"
                >
                  <img src="@/assets/images/top/twitter.png" alt="" />
                </a>
                <a
                  :href="$config.instagram.link"
                  target="_blank"
                  class="share__item share__instagram"
                >
                  <img src="@/assets/images/top/ig.png" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="inhalt-movie">
          <img src="@/assets/images/inhalt/movie.png" alt="" :class="$mq" />
        </div>
        <div class="inhalt-text">
          <div class="h1">
            <h1>
              Eine poetische Liebeserklärung an alle, die sich<br />
              danach sehnen, aus der Konformität auszubrechen.
            </h1>
          </div>
          <div class="inhalt-text-content">
            <div class="text-left" :class="$mq">
              <p>
                Familie Borghetti ist gerade in ein schickes neues Apartment
                gezogen, mit Blick über ganz Rom. Eine aufregende Stadt im
                Umbruch der 70er Jahre. Doch die Fassade der italienischen
                Familie bröckelt. Clara ist in einer lieblosen Ehe gefangen und
                während draußen die sommerlichen Temperaturen nach oben
                klettern, wird die Stimmung zu Hause immer eisiger. Und auch
                Adri, die älteste Tochter, hat Sorgen. Sie hat das Gefühl, im
                falschen Körper zu stecken, verliebt sich in ein Mädchen und
                stellt sich in der neuen Nachbarschaft als Junge vor. Während
                Clara darum kämpft, für ihre Kindern stark zu bleiben, wagt Adri
                den Ausbruch und begibt sich auf die Suche nach der eigenen
                Identität.
              </p>
            </div>
            <div class="text-right" :class="$mq">
              <p class="text-right-top">
                <span>
                  In L’IMMENSITÀ – MEINE FANTASTISCHE MUTTER zeichnet Regisseur
                  und Drehbuchautor Emanuele Crialese („Lampedusa“) das
                  ergreifende Porträt einer Familie im Umbruch:
                  Oscar®-Preisträgerin Penélope Cruz glänzt hier einmal mehr in
                  ihrer Paraderolle als liebende Mutter am Rande des
                  Nervenzusammenbruchs. In magischen Bildern und mit
                  mitreißendem Italo-Pop-Soundtrack nimmt L’IMMENSITÀ – MEINE
                  FANTASTISCHE MUTTER den Zuschauer mit auf eine sommerliche
                  Reise in das Rom der wilden, bunten 1970er Jahre.</span
                >
              </p>
            </div>
          </div>
        </div>
        <div class="zitat-content zitat-undertext">
          <div class="zitat-content-text">
            „Penélope Cruz ist eine Wucht, eine Naturgewalt.”
          </div>
          <div class="zitat-content-author">KINO-ZEIT.DE</div>
        </div>
        <div class="inhalt-fotos" :class="$mq">
          <!--          <img src="@/assets/images/inhalt/fotos.png" alt="" :class="$mq" />-->
          <div class="inhalt-fotos-content">
            <div class="bild_02">
              <img
                src="@/assets/images/inhalt/bild_02.png"
                alt=""
                :class="$mq"
              />
            </div>
            <div class="bild_01">
              <img
                src="@/assets/images/inhalt/bild_01.png"
                alt=""
                :class="$mq"
              />
            </div>
            <div class="bild_03">
              <img
                src="@/assets/images/inhalt/bild_03.png"
                alt=""
                :class="$mq"
              />
            </div>
          </div>
        </div>
        <div class="dvd" id="dvd">
          <div class="wrapper container">
            <div class="left_side">
              <img
                class="packshot"
                src="@/assets/images/dvd/packshot.png"
                alt=""
              />
            </div>
            <div class="right_side">
              <div class="txt">
                <p>
                  <span class="extra">DVD</span>
                </p>
                <div class="amz-btn">
                  <a
                    href="https://amzn.to/3Pu53IX"
                    class="vod_btn"
                    target="_blank"
                  >
                    <img src="@/assets/images/dvd/amazon.jpg" alt="" />
                  </a>
                  <!--<span>*</span>-->
                </div>
              </div>
              <div class="video">
                <h2>Video on Demand</h2>
                <ul class="vod_btns">
                  <li>
                    <a
                      href="https://tv.apple.com/de/movie/limmensita---meine-fantastische-mutter/umc.cmc.ic6cq1uk7v8tqz9ch9908dk"
                      target="_blank"
                      ><img src="@/assets/images/dvd/itunes.jpg"
                    /></a>
                  </li>
                  <li>
                    <a href="https://amzn.to/45eB3FE" target="_blank"
                      ><img src="@/assets/images/dvd/prime.jpg"
                    /></a>
                  </li>
                  <li>
                    <a
                      href="https://play.google.com/store/movies/details/L_Immensit%C3%A0_Meine_fantastische_Mutter?id=YHzcSJQshvQ.P&gl=DE"
                      target="_blank"
                      ><img src="@/assets/images/dvd/google.jpg"
                    /></a>
                  </li>
                  <li>
                    <a
                      href="https://www.videoload.de/film/l-immensita-meine-fantastische-mutter/GN_MV020370820000"
                      target="_blank"
                      ><img src="@/assets/images/dvd/videoload.jpg"
                    /></a>
                  </li>
                  <li>
                    <a
                      href="https://ondemand.yorck.de/film/limmensita/"
                      target="_blank"
                      ><img src="@/assets/images/dvd/yorck.jpg"
                    /></a>
                  </li>
                  <li>
                    <a
                      href="https://store.sky.de/product/limmensita-meine-fantastische-mutter/5b3d6d34-414d-4d4f-a533-c3d683eb141a"
                      target="_blank"
                      ><img src="@/assets/images/dvd/sky.jpg"
                    /></a>
                  </li>
                  <li>
                    <a
                      href="https://www.kino-on-demand.com/movies/l-immensita-meine-fantastische-mutter"
                      target="_blank"
                      ><img src="@/assets/images/dvd/ondemand.png"
                    /></a>
                  </li>
                  <li>
                    <a
                      href="https://www.rakuten.tv/de/search?q=limmensita&content_type=movies&content_id=l-immensita-meine-fantastische-mutter"
                      target="_blank"
                      ><img src="@/assets/images/dvd/rakuten.jpg"
                    /></a>
                  </li>
                </ul>
                <p class="note">
                  * Die mit Sternchen (**) gekennzeichneten Links sind
                  sogenannte Affiliate-Links. Wenn Sie auf einen Affiliate-Link
                  klicken und über<br />
                  diesen Link einkaufen, erhält Prokino vom betreffenden
                  Online-Shop oder Anbieter eine Provision. Für Sie verändert
                  sich der Preis nicht.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="inhalt-zitate">
          <div class="zitat-content zitat-1">
            <div class="zitat-content-text">„Herrlich anzuschauen”</div>
            <div class="zitat-content-author">SÜDDEUTSCHE ZEITUNG</div>
          </div>
          <div class="zitat-content zitat-2">
            <div class="zitat-content-text">„Herzerweichend”</div>
            <div class="zitat-content-author">ABENDZEITUNG</div>
          </div>
        </div>
      </div>
    </div>
    <div class="rote-linie" :class="$mq">
      <img
        src="@/assets/images/footer/rote_linie_small.png"
        alt=""
        class="rote_small"
        :class="$mq"
      />
      <img
        src="@/assets/images/footer/rote_linie_long.png"
        alt=""
        class="rote_long"
        :class="$mq"
      />
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/plugins/event-bus.js'
export default {
  props: {
    kinostart: {
      type: String,
      default: 'Releaseline',
    },
    ratio: {
      type: Number,
      default: 1.78,
    },
    trailer: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isFixed() {
      return this.position === 'fixed'
    },
  },

  methods: {
    ytVideo() {
      EventBus.$emit('open-yt')
    },
    kinoliste() {
      EventBus.$emit('open-kinoliste')
    },
  },
}
</script>

<style lang="scss">
.inhalt-overview {
  display: flex;
  flex-direction: column;
  background-image: url('~@/assets/images/inhalt/bg.jpg');
  background-size: cover;
  background-position: center;
  height: 2624px;
  position: relative;
  &.lg,
  &.xl {
    height: 2724px;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  &.xxxl,
  &.xxxxl {
    background-image: url('~@/assets/images/inhalt/bg_large.jpg');
    height: 2568px;
  }
  .inhalt {
    z-index: 99;
    $self: &;
    width: 100%;
    font-family: 'Raleway';
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .desktop-inhalt-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 1308px;
      .teilen-desktop {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        .teilen-desktop-content {
          border-bottom: 1px solid #878787;
          border-top: 1px solid #878787;
          padding-bottom: 9px;
          padding-top: 14px;
          margin-bottom: 29px;
          .sharing {
            display: flex;
            flex-direction: row;
            &.lg {
              span {
                font-size: 15px;
              }
            }
            .bg-teilen {
              background-color: white;
              width: 164px;
              height: 39px;
              border-radius: 30px;
              margin-top: 7px;
              text-align: center;
              span {
                font-size: 17px;
                font-weight: bold;
                font-family: 'Raleway';
                text-align: center;
                text-transform: uppercase;
                line-height: 1.7;
                color: #c42222;
                margin-right: -40px;
              }
            }
            .share__fb,
            .share__twitter,
            .share__instagram {
              margin-right: 10px;
              img {
                width: 20%;
              }
            }
          }
        }
      }
      .inhalt-movie {
        margin-bottom: 49px;
      }
      .inhalt-text {
        h1 {
          text-align: center;
          font-size: 30px;
          font-family: 'Roboto Slab';
          color: #c71617;
          font-weight: 500;
        }
        .inhalt-text-content {
          display: flex;
          flex-direction: row;
          justify-content: center;
          .text-left {
            width: 45%;
            margin-right: 18px;
            display: flex;
            justify-content: center;
            align-items: center;

            &.xl {
              width: 40%;
            }
            &.lg {
              width: 30%;
            }
            p {
              width: 100%;
            }
          }
          .text-right {
            width: 50%;
            margin-left: 18px;
            display: flex;
            align-items: center;
            flex-direction: column;
            .text-bottom {
              margin-top: 0 !important;
            }
            &.xl {
              width: 40%;
            }
            &.lg {
              width: 30%;
            }
          }
          span {
            font-weight: bold;
          }
          .text-left p,
          .text-right p {
            line-height: 1.5;
            font-size: 17px;
            font-weight: 500;
            text-align: justify;
          }
        }
      }
      .zitat-undertext {
        margin: 5px 0;
      }
      .zitat-content {
        text-align: center;
        .zitat-content-text {
          font-family: 'Roboto Slab';
          font-size: 25px;
          color: #c71617;
        }
        .zitat-content-author {
          margin-bottom: 15px;
          margin-top: 5px;
          font-size: 15px;
          font-family: 'Raleway';
          font-weight: 500;
        }
      }

      .inhalt-fotos {
        margin-top: 33px;
        margin-bottom: 36px;
        height: auto;
        width: 100%;
        .inhalt-fotos-content {
          position: relative; // Stellt sicher, dass die Positionierung der Bilder relativ zu diesem Container ist
          height: 500px;
          .bild_01 {
            position: absolute; // Absolute Positionierung, um die Bilder übereinander zu stapeln
            top: 190px; // Anpassen der Position der einzelnen Bilder (kann je nach Bedarf variieren)
            left: 50%;
            transform: translateX(-50%);
            z-index: 88888;
          }
          .bild_02 {
            position: absolute; // Absolute Positionierung, um die Bilder übereinander zu stapeln
            top: -5px; // Anpassen der Position der einzelnen Bilder (kann je nach Bedarf variieren)
            left: 85px;
          }
          .bild_03 {
            position: absolute; // Absolute Positionierung, um die Bilder übereinander zu stapeln
            top: -20px; // Anpassen der Position der einzelnen Bilder (kann je nach Bedarf variieren)
            right: 50px;
          }
        }
      }
      .dvd {
        z-index: 99;
        $self: &;
        width: 1024px;
        height: 388px;
        font-family: 'Urbanist';
        padding: 0;
        padding-bottom: 20px;
        margin: 0 auto 20px auto;
        .container {
          padding: 0;
        }
        .wrapper {
          display: flex;
          justify-content: center;
          width: 100%;
          .left_side {
            margin-top: 25px;
            margin-right: -30px;
          }

          .right_side {
            margin-top: 25px;
            .txt {
              margin-top: 10px;
              flex: 1;
              margin-left: 66px;
              display: flex;
              flex-direction: row;
              align-items: center;
              .extra {
                font-weight: 400;
                font-family: 'Roboto Slab';
                font-size: 30px;
                color: white;
              }
              p {
                margin-bottom: 0;
              }
              ul {
                margin-top: 10px;
                padding-left: 0 !important;
              }

              ul,
              li {
                //font-size: 6px;
                margin-left: 10px;
                margin-bottom: 0;
              }
              li span {
                font-size: 18px;
              }
              .amz-btn {
                display: flex;
                height: 46px;
                margin-left: 10px;
                margin-top: 10px;
                .disabled {
                  pointer-events: none;
                  opacity: 0.5;
                }

                span {
                  display: block;
                  margin-left: 3px;
                  font-weight: 700;
                  font-family: 'Oswald';
                }
              }

              .vod_btn {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 42px;
                margin-top: 5px;
                margin-bottom: 77px;
                .disabled {
                  pointer-events: none;
                  opacity: 0.5;
                }

                img {
                  vertical-align: middle;
                  max-height: 100%;
                }
              }
            }

            h2 {
              font-size: 30px;
              text-transform: uppercase;
              color: white;
              margin-left: 66px;
              font-weight: 400;
              font-family: 'Roboto Slab';
              margin-bottom: 0 !important;
              margin-top: 30px !important;
            }

            .vod_btns {
              margin-left: 60px;
              position: relative;
              width: 569px;
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              align-content: flex-start;
              /* vertical */
              justify-content: flex-start;
              /* horizontal */
              align-items: stretch;
              padding: 0 !important;
              margin-top: 0 !important;
              /* same height */
              .disabled {
                pointer-events: none;
                opacity: 0.5;
              }

              li {
                list-style: none;
                margin: 7px;
              }

              .vod_btn {
                flex: 0 1 50%;
                margin: 0 20px 14px 0;
                padding: 0 !important;
                .disabled {
                  pointer-events: none;
                  opacity: 0.5;
                }
              }
            }
            .note {
              font-size: 10px;
              line-height: 0.8rem;
              font-weight: 500;
              text-align: left;
              font-family: 'Roboto';
              margin-top: -10px;
              margin-left: 65px;
            }
          }
        }
      }
      .inhalt-zitate {
        .zitat-1 {
          margin-left: -325px;
        }
        .zitat-2 {
          margin-right: -310px;
          margin-top: -70px;
        }
        .zitat-content {
          text-align: center;
          .zitat-content-text {
            font-family: 'Roboto Slab';
            font-size: 25px;
            color: white;
          }
          .zitat-content-author {
            margin-bottom: 15px;
            margin-top: 5px;
            font-size: 15px;
            font-family: 'Raleway';
            font-weight: 500;
          }
        }
      }
    }
  }
  .rote-linie {
    position: absolute;
    bottom: -6px;
    width: 100%;
    img {
      width: 100%;
    }
    .rote_small {
      &.lg,
      &.xl,
      &.xxl {
        display: block;
      }
      &.xxxl,
      &.xxxxl {
        display: none;
      }
    }
    .rote_long {
      &.lg,
      &.xl,
      &.xxl {
        display: none;
      }
      &.xxxl,
      &.xxxxl {
        display: block;
      }
    }
  }
}
</style>
