<!-- @format -->
<template>
  <div class="base-kinostart" :class="$mq">
    <!-- eslint-disable-next-line -->
    <div class="text" :class="$mq" v-html="kinostart"></div>
  </div>
</template>
<script>
  export default {
    props: {
      kinostart: {
        type: String,
        default: 'Releaseline',
      },
    },
  }
</script>
<style lang="scss">
  .base-kinostart {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: #c42222;
    font-weight: 400;
    font-family: 'Roboto Slab';
    margin: 10px 0;
    &.md {
      text-align: center;
      width: 100%;
      margin: 0 auto;
      font-size: 34px !important;
    }
    &.sm {
      font-size: 27px !important;
      text-align: center;
      width: 100%;
      margin: 0 auto;
    }
    /*  &.sm {
    font-size: 36px;
    padding: 10px 0;
    margin: 0 auto;
    background-color: white;
    text-align: center;
  }
  &.md {
    font-size: 55px;
    padding: 10px 0;
    margin: 0;
    background-color: white;
    text-align: center;
  } */
  }
</style>
